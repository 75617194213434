// Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase,
// DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper,
// SplitText, CustomBounce, CustomWiggle,
import { gsap, ScrollTrigger, DrawSVGPlugin, ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin, ScrollToPlugin);
export default () => {
  //------------------------------------------------------//
  // Setup 🧦 GSAP and register 📜 ScrollTrigger
  // Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
  // Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
  //------------------------------------------------------//
  // gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers: window.location.hostname.endsWith(".local") ? true : false,
    // markers: true,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //--------------------------------//
  // ➡️ Horizontal logic
  //--------------------------------//
  // Page
  document.querySelectorAll(".horizontal").forEach((container) => {
    document.querySelectorAll(".flex-overview-nowrap").forEach((item) => {
      gsap.set(".alloy-sf-item", {
        y: () => gsap.utils.random(0, -(window.innerHeight * 0.25)),
      });
    });
    // Get all direct childern `of the element called .horizontal
    const sections = container.children;

    // Cacluate the total width of all the childeren
    const totalWidth = Array.from(sections)
      .map((section) => {
        return section.offsetWidth;
      })
      .reduce((acc, width) => {
        return acc + width;
      }, 0);
    const totalMove = totalWidth - window.innerWidth;

    const tl = gsap.timeline({
      defaults: {
        ease: "none",
      },
      scrollTrigger: {
        trigger: container,
        pin: true,
        scrub: 0.5,
        end: () => `+=${totalMove})`,
      },
    });
    tl.to(sections, {
      x: () => -totalMove,
    });
    // let distance = 0;

    // ScrollTrigger.observe({
    //   target: window, // can be any element (selector text is fine)
    //   type: "wheel,touch", // comma-delimited list of what to listen for ("wheel,touch,scroll,pointer")
    //   tolerance: 50,
    //   onLeft: (self) => {
    //     if (Math.abs(self.velocityX * 1) > distance) {
    //       distance = Math.abs(self.velocityX * 1);
    //       gsap.to(window, {
    //         scrollTo: `-=${Math.abs(self.velocityX * 1)}`,
    //         ease: "none",
    //         onComplete: () => {
    //           distance = 0;
    //         },
    //       });
    //     }
    //   },
    //   onRight: (self) => {
    //     if (Math.abs(self.velocityX * 1) > distance) {
    //       distance = Math.abs(self.velocityX * 1);
    //       d;
    //       gsap.to(window, {
    //         scrollTo: `+=${Math.abs(self.velocityX * 1)}`,
    //         ease: "none",
    //         onStart: () => {
    //           console.warn("start");
    //         },
    //         onComplete: () => {
    //           distance = 0;
    //         },
    //       });
    //     }
    //   },
    // });
  });
  // Content
  document.querySelectorAll(".content-horizontal").forEach((container) => {
    // Get all direct childern `of the element called .horizontal
    const sections = container.querySelectorAll(
      ".horizontal-overview .alloy-card",
    );
    // Cacluate the total width of all the childeren
    const totalWidth = Array.from(sections)
      .map((section) => section.offsetWidth)
      .reduce((acc, width) => acc + width, 0);

    // Only do the horizontal logic if the content is wider than the viewport
    if (totalWidth > window.innerWidth) {
      const totalMove = totalWidth - window.innerWidth;

      const tl = gsap.timeline({
        defaults: {
          ease: "none",
        },
        scrollTrigger: {
          trigger: container,
          pin: "main",
          pinnedContainer: "main",
          scrub: 0.5,
          start: "center center",
          end: () => `+=${totalMove})`,
        },
      });
      tl.to(container.querySelector(".horizontal-overview"), {
        x: () => -totalMove,
      });
    }
  });
  // END ➡️ Horizontal logic --------------//

  //--------------------------------//
  // 🌍 header map
  //--------------------------------//
  document.querySelectorAll(".alloy-header-type-kaart").forEach((container) => {
    // Streamers
    const q = gsap.utils.selector(container);
    const random = gsap.utils.random(0, 40, true);
    gsap.set(q(".streamers"), {
      autoAlpha: 1,
      opacity: 0.2,
    });
    q(".streamers path").forEach((item) => {
      const tl = gsap.timeline({
        delay: random(),
        repeat: -1,
        repeatDelay: random(),
        defaults: {
          duration: 2,
        },
      });

      tl.from(item, {
        drawSVG: "0%",
      });
      tl.to(item, {
        drawSVG: "100% 100%",
      });
    });

    // Water bodies
    q(".rivers > *").forEach((river) => {
      const x = gsap.utils.selector(river);
      const tl = gsap.timeline({
        paused: true,
      });

      tl.from(x("text"), {
        opacity: 0,
      });
      // tl.to(
      //   x("path"),
      //   {
      //     drawSVG: "100% 100%",
      //   },
      //   "<",
      // );
      // tl.to(x("path"), {
      //   drawSVG: "100%",
      // });

      // Create mouse enter and leave .play() .reverse()
      river.addEventListener("mouseenter", () => {
        tl.play();
      });
      river.addEventListener("mouseleave", () => {
        tl.reverse();
      });
    });
    // END 🌍 header map --------------//
  });

  //--------------------------------//
  // Notice
  //--------------------------------//
  document.querySelectorAll(".alloy-notice").forEach((container) => {
    const q = gsap.utils.selector(container);
    const loop = horizontalLoop(q(".inner"), {
      repeat: -1,
      speed: 0.5,
    });
    let tl = gsap
      .timeline({
        defaults: {
          ease: "none",
        },
      })
      .to(loop, { timeScale: -1, duration: 1 });
    ScrollTrigger.observe({
      onChangeY(self) {
        let factor = 2.5;
        if (self.deltaY < 0) {
          factor *= -1;
        }
        tl.clear();
        tl.to(loop, { timeScale: factor, duration: 1 }, "+=0.3");
      },
    });
  });

  // Loop function
  function horizontalLoop(items, config) {
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: "none" },
        onReverseComplete: () =>
          tl.totalTime(tl.rawTime() + tl.duration() * 100),
      }),
      length = items.length,
      startX = items[0].offsetLeft,
      times = [],
      widths = [],
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 100,
      snap =
        config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
      totalWidth,
      curX,
      distanceToStart,
      distanceToLoop,
      item,
      i;
    gsap.set(items, {
      // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
      xPercent: (i, el) => {
        let w = (widths[i] = parseFloat(gsap.getProperty(el, "width", "px")));
        xPercents[i] = snap(
          (parseFloat(gsap.getProperty(el, "x", "px")) / w) * 100 +
            gsap.getProperty(el, "xPercent"),
        );
        return xPercents[i];
      },
    });
    gsap.set(items, { x: 0 });
    totalWidth =
      items[length - 1].offsetLeft +
      (xPercents[length - 1] / 100) * widths[length - 1] -
      startX +
      items[length - 1].offsetWidth *
        gsap.getProperty(items[length - 1], "scaleX") +
      (parseFloat(config.paddingRight) || 0);
    for (i = 0; i < length; i++) {
      item = items[i];
      curX = (xPercents[i] / 100) * widths[i];
      distanceToStart = item.offsetLeft + curX - startX;
      distanceToLoop =
        distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
      tl.to(
        item,
        {
          xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
          duration: distanceToLoop / pixelsPerSecond,
        },
        0,
      )
        .fromTo(
          item,
          {
            xPercent: snap(
              ((curX - distanceToLoop + totalWidth) / widths[i]) * 100,
            ),
          },
          {
            xPercent: xPercents[i],
            duration:
              (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
            immediateRender: false,
          },
          distanceToLoop / pixelsPerSecond,
        )
        .add("label" + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
      vars = vars || {};
      Math.abs(index - curIndex) > length / 2 &&
        (index += index > curIndex ? -length : length); // always go in the shortest direction
      let newIndex = gsap.utils.wrap(0, length, index),
        time = times[newIndex];
      if (time > tl.time() !== index > curIndex) {
        // if we're wrapping the timeline's playhead, make the proper adjustments
        vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
        time += tl.duration() * (index > curIndex ? 1 : -1);
      }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
    }
    return tl;
  }
  // END Notice --------------//
};
