export default () => {
  //------------------------------------------------------//
  // 📝 Form labels
  //------------------------------------------------------//
  document
    .querySelectorAll(".gfield input, .gfield textarea")
    .forEach(function (input) {
      // Check if value
      if (input.value) {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL"
        ) {
          input.previousElementSibling.classList.add("selectedInput");
        }
        input
          .closest(".gfield")
          .querySelector(".gfield_label")
          .classList.add("selectedInput");
      }
      // On focus
      input.addEventListener("focus", (event) => {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL" &&
          !input.value
        ) {
          input.previousElementSibling.classList.add("selectedInput");
        }
        input
          .closest(".gfield")
          .querySelector(".gfield_label")
          .classList.add("selectedInput");
      });
      // On focus loss
      input.addEventListener("blur", (event) => {
        if (
          input.previousElementSibling &&
          input.previousElementSibling.tagName == "LABEL" &&
          !input.value
        ) {
          input.previousElementSibling.classList.remove("selectedInput");
        }
        if (!input.value) {
          input
            .closest(".gfield")
            .querySelector(".gfield_label")
            .classList.remove("selectedInput");
        }
      });
    });
  // END 📝 Form labels -------------------------------------//
};
