import { gsap, ScrollTrigger, Flip } from "gsap/all";

gsap.registerPlugin(Flip, ScrollTrigger);

export default () => {
  //--------------------------------//
  // Slider
  //--------------------------------//
  document.querySelectorAll(".slider-container").forEach((container) => {
    // Setup variables
    const items = container.querySelectorAll(".slide"),
      itemsMax = items.length - 1;
    const next = container.querySelector(".next"),
      prev = container.querySelector(".prev"),
      dots = container.querySelector(".dots");
    let index = 0;
    const move = 100;
    const timer = 5000;
    const duration = 0.5;
    let autoplay;
    //--------------------------------//
    // 💾 Inital setup
    //--------------------------------//
    function init() {
      // Create 🟣 Dots
      items.forEach((item, index) => {
        const dot = document.createElement("li");
        if (index === 0) dot.classList.add("active"); // Set the first dot to active
        // Allow clicking the dots
        dot.addEventListener("click", () => {
          slideLogic(false, index);
        });

        if (dots) dots.appendChild(dot);
      });
      // Animate the first element in
      gsap.set(items, { autoAlpha: 0 });
      gsap.set([".slider", items[index]], { autoAlpha: 1 });
      gsap.from(items[index], { autoAlpha: 0, x: move });
      autoplay = window.setTimeout(slideLogic, timer);
    }
    // Run inital setup
    init();
    // END 💾 Inital setup --------------//

    //--------------------------------//
    // ☝️ Touch
    //--------------------------------//
    if (window.Draggable) {
      Draggable.create(items, {
        type: "x", // Drag only on the X axis
        zIndexBoost: false,
        onDragStart: function () {
          window.clearTimeout(autoplay); // disable autoplay
          slideLogic(this.getDirection() === "right" ? true : false);
        },
      });
    }
    // END ☝️ Touch  --------------//

    //--------------------------------//
    // Set active 🟣 dot
    //--------------------------------//
    function dotActive(index) {
      const dotsAll = dots.querySelectorAll("li");
      dotsAll.forEach((dot) => {
        dot.classList.remove("active");
      });
      dotsAll[index].classList.add("active");
    }
    // END Set active 🟣 dot --------------//

    //--------------------------------//
    // 🖼️ Slide animation
    //--------------------------------//
    function slideAnimation(index, moveIn, outIn) {
      // Reset the properties you are animating below
      // Ohter wise they animate from that positon (probalby not visable)
      gsap.set(items, { xPercent: 0, scale: 1 });
      gsap.set(items[moveIn], { autoAlpha: 1 });

      // The animation
      const tl = gsap.timeline({
        defaults: {
          duration: duration,
        },
        onStart: animationStart,
        onComplete: animationDone,
      });
      // Move out slide
      tl.to(items[index], { xPercent: outIn[0] });
      // move in slide
      tl.from(items[moveIn], { xPercent: outIn[1] }, "<");
      // Animate text
      tl.set(items[index], { autoAlpha: 0 }); // Hide moved out slide
    }
    function animationStart() {
      container.classList.add("running");
    }
    function animationDone() {
      toggleButtons();
      autoplay = window.setTimeout(slideLogic, timer);
      container.classList.remove("running");
      gsap.set(items, { x: 0 });
    }
    // END 🖼️ Slide animation --------------//

    //--------------------------------//
    // Slider 🎛️ logic
    //--------------------------------//
    function slideLogic(prev, customMoveIn) {
      toggleButtons(); // Disable buttons
      window.clearTimeout(autoplay); // disable autoplay
      let outIn = [-move, move];
      if (prev) outIn.reverse();
      let moveIn;
      // Check if moveIn is passed with the function
      if (typeof customMoveIn === "undefined") {
        if (prev) {
          moveIn = index === 0 ? itemsMax : index - 1;
        } else {
          moveIn = index === itemsMax ? 0 : index + 1;
        }
      } else {
        moveIn = customMoveIn;
      }
      if (dots) dotActive(moveIn); // Set active dot
      slideAnimation(index, moveIn, outIn); // Animation function
      // Changing the next index
      if (typeof customMoveIn === "undefined") {
        if (prev) {
          index === 0 ? (index = itemsMax) : index--;
        } else {
          index === itemsMax ? (index = 0) : index++;
        }
      } else {
        index === itemsMax ? (index = 0) : (index = customMoveIn++);
      }
    }
    // END Slider 🎛️ logic --------------//

    //--------------------------------//
    // Button 🎛️ control
    //--------------------------------//
    function toggleButtons() {
      if (next) next.disabled = !next.disabled;
      if (prev) prev.disabled = !prev.disabled;
    }
    if (next) next.addEventListener("click", () => slideLogic());
    if (prev) prev.addEventListener("click", () => slideLogic(true));
    // END Button 🎛️ control --------------//
  });
  // END Slider --------------//

  //--------------------------------//
  // 🌆 Photo grid
  //--------------------------------//
  // Logic for each photo grid
  document.querySelectorAll(".ACF-photo_grid").forEach((container) => {
    // Get interactive elements
    const items = container.querySelectorAll(".image img");
    items.forEach((item) => {
      // Add click function
      item.addEventListener("click", () => {
        // This ia all you need for the flip animation to work
        const state = Flip.getState(item);
        const isOpen = item.classList.toggle("opened");
        // Animate
        Flip.from(state, {
          // Customize animation here
          absolute: true,
        });
      });
    });
  });

  // END 🌆 Photo grid --------------//

  //--------------------------------//
  // Clip path effects
  //--------------------------------//
  const tlClipEffect = gsap.timeline({});

  tlClipEffect.from(".alloy-clip-effects rect", {
    scaleY: 0,
    transformOrigin: "50% 50%",
    stagger: {
      amount: 1,
      from: "center",
    },
  });

  document.querySelectorAll(".alloy-clip").forEach((item) => {
    ScrollTrigger.create({
      trigger: item,
      start: "top top",
      end: "bottom top",
      toggleActions: "restart none none none",
      animation: tlClipEffect,
    });
  });
  // END Clip path effects --------------//
};
